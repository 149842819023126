* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes raindrop {
  to {
    top: 100vh;
  }
}

@keyframes raindrop {
  to {
    top: 100vh;
  }
}

